@import url('https://fonts.googleapis.com/css?family=Raleway:300,400,500,700&display=swap');
:root{
  --sidebar-flex:1.5;
  --condiv-flex:6;
  --fontsize-p:1.2rem;
  --fontsize-nav:1.2rem;
  --typing-font:1.8rem;
}
*
{
  margin:0;
  padding: 0;
}
body{
  font-family: 'Raleway';
  background: #dde1e7;
}
.App
{
  display: flex;
  width: 100vw;
  min-height: 100vh;
}
nav
{
  width: 20%;
	height: 90%;
	position: absolute;
	top: 4%;
	left: 4%; 
	background: #dde1e7;
	border-radius: 30px;
	padding: 0;
  line-height: 1;
  text-align: center;
  box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(18, 18, 19, 0.288);
}
.condiv
{
  width: 60%;
	height: 90%;
	position: absolute;
	top: 4%;
	left: 28%; 
	border-radius: 30px;
  line-height: 1;
  padding-left: 10px;
  padding-right: 10px;
  background: #dde1e7;
  box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(18, 18, 19, 0.288);
}
.neu1{
  width: 180px;
  height: 180px;
  margin: 10px;
  border-radius: 50%;
  box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(18, 18, 19, 0.288);
}
.neu2{
  position: absolute;
  top: 2%;
  left: 12%;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  box-shadow: inset -3px -3px 7px #ffffff73, inset 3px 3px 5px rgba(18, 18, 19, 0.288);
}

.home
{
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  
}
.profilepic
{
  border-radius: 50%;
  width: 160px;
  margin: 10px;
}
.typingeffect
{
  font-size: var(--typing-font);
  margin-bottom: 30px;
  color: #6c757d;
}

nav ul{
  position: absolute;
  bottom: 2%;
  left: 5%;
  width: 90%;
  font-size:var(--fontsize-nav) ;
  text-align: center;
  list-style-type: none;
  text-decoration: none;
  color: #969696 !important;
}
nav ul li
{
  margin:15px;
  padding: 11px;
  border-radius: 50px;
  box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(18, 18, 19, 0.288);
}
nav ul li:hover{
  box-shadow: inset -3px -3px 7px #ffffff73, inset 3px 3px 5px rgba(18, 18, 19, 0.288);
}

a
{
  text-decoration: none;
}

.subtopic
{
  margin:10px 10px 10px 15px;
  font-size: 1.6rem;
}
p{
  font-size: var(--fontsize-p);
  color: #6c757d;
  margin: 10px;
}
h1{
  margin: 10px;
  font-size: 3.3rem;
}
h2{
  margin-top: 90%;
  font-size: 1.8rem;
}
.abouthead{
  margin: 15px 10px;
  font-size: 2.3rem;
}
h3{
  font-size: 1.4rem;
  margin: 37px 0 0 90px;
}
h4{
  font-size: 1.2rem;
  margin: 24px 0 0 90px;
}
.p1{
  margin-left: 90px;
  font-size: 1rem;
}
.sk_head{
  position: absolute;
  top: 50%;
  left: 45%;
}
.sk{
  position: absolute;
  top: 65%;
  left: 55%;
  display: inline-block;
  width: 90px;
  height: 15px;
  font-weight: bolder;
  color: rgba(245, 108, 109,1);
  text-align: center;
  padding: 10px;
  border-radius: 50px;
  box-shadow: inset -3px -3px 7px #ffffff73, inset 3px 3px 5px rgba(18, 18, 19, 0.288);
}
.s2{
  top: 65%;
  left: 70%;
}
.s3{
  top: 65%;
  left: 85%;
}
.s4{
  top: 75%;
  left: 85%;
}
.s5{
  top: 75%;
  left: 55%;
}
.s6{
  top: 75%;
  left: 70%;
}
.s7{
  top: 85%;
  left: 55%;
}
.s8{
  top: 85%;
  left: 70%;
}
.s9{
  top: 85%;
  left: 85%;
}
mark{
  color: inherit;
  padding: 0;
  background: 0 0;
  background-position-x: 0px;
  background-position-y: 0px;
  background-repeat: repeat;
  background-size: auto;
  background-image: linear-gradient(120deg,rgba(245, 108, 109,1) 0,rgba(245, 108, 109,1) 100%);
  background-repeat: no-repeat;
  background-size: 100% .28em;
  background-position: 0 70%;
  position: relative;
}
.hr_line{
  width: 60px;
  height: 10px;
  border-radius: 5px;
  box-shadow: inset -3px -3px 7px #ffffff73, inset 3px 3px 5px rgba(18, 18, 19, 0.288);
}
.hr_inner{
  width: 30px;
  height: 5px;
  border-radius: 5px;
  margin: 2.5px 15px;
  background: linear-gradient(120deg,rgba(245, 108, 109,1) 0,rgba(245, 108, 109,1) 100%);
}
.line2{
  margin: 1px 25px;
  height: 5px;
}
.inner2{
  background: rgba(245, 108, 109,1);
  width: 30px;
  height: 5px;
  border-radius: 5px;
  margin: 7px;
}
.vr_line{
  width: 10px;
  height: 210px;
  border-radius: 5px;
  position: absolute;
  top: 58%;
  left: 6.25%;
  box-shadow: inset -3px -3px 7px #ffffff73, inset 3px 3px 5px rgba(18, 18, 19, 0.288);
}
.vr_inner{
  width: 10px;
  height: 15px;
  border-radius: 5px;
  background: linear-gradient(120deg,rgba(245, 108, 109,1) 0,rgba(245, 108, 109,1) 100%);
}
.inn1{
  margin-top: 45px;
}
.inn2{
  margin-top: 80px;
}
.social
{
  width: 50%;
	height: 14%;
	position: absolute;
	bottom: 4%;
	right: 25%; 
	background: #dde1e7;
	border-radius: 5px;
	display: flex;
	justify-content: center;
  align-items: center;
  box-shadow: inset -3px -3px 7px #ffffff73, inset 3px 3px 5px rgba(18, 18, 19, 0.288);
}
.btnsame{
	background: #dde1e7;
	height: 50px;
	width: 50px;
	margin: 15px 20px;
	border-radius: 5px;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	transition: all 400ms;
	box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(18, 18, 19, 0.288);
	border: 1px solid rgba(255, 255, 255, 0.3);
	position: relative;
}
.btnsame i{
  font-size: 28px;
  transition: all 400ms;
  position: absolute;
  top: 20%;
  left: 25%;
  color: #6c757d;
}
.btn1:hover i{
  color: #43de85;
}
.btn2:hover i{
  color:#0088ee;
}
.btn3:hover i{
  color:#3b5998;
}
.btn4:hover i{
  color: #ee6644;
}
.active
{
  color: #0088ee;
  box-shadow: inset -3px -3px 7px #ffffff73, inset 3px 3px 5px rgba(18, 18, 19, 0.288);
  font-weight: bolder;
}
.port_out{
  position: absolute;
  width: 38%;
  height: 30%;
  border-radius: 10px;
  box-shadow: inset -3px -3px 7px #ffffff73, inset 3px 3px 5px rgba(18, 18, 19, 0.288);
}
.port1{
  top: 18%;
  left: 10%;
}
.port2{
  top: 18%;
  left: 53%;
}
.port3{
  top: 58%;
  left: 10%;
}
.port4{
  top: 58%;
  left: 53%;
}
.port_in{
  position: absolute;
  width: 93%;
  height: 89%;
  margin: 3% 3%;
  border-radius: 10px;
  box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(18, 18, 19, 0.288);
}
.po1{
  width: 96%;
  height: 93%;
  margin: 2% 2%;
  border-radius: 10px;
}
.line3{
  position: absolute;
  top: 70%;
  left: 45%;
}
.inner3{
  margin: 3px 15px;
  background: rgba(245, 108, 109,1);
  width: 30px;
  height: 5px;
  border-radius: 5px;
}
.con_out{
  position: absolute;	
  width: 88%;
  top: 13%;
  left: 6%;
  border-radius: 10px;
  box-shadow: inset -3px -3px 7px #ffffff73, inset 3px 3px 5px rgba(18, 18, 19, 0.288);
}
.con_in{
  width: 98%;
  height: 90.5%;
  margin: 8px 8px;
  border-radius: 10px;
  box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(18, 18, 19, 0.288);
}
.co1{
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

.back1{
	width: 50px;
	height: 50px;
	position: absolute;
	top: 4%;
	right: 2%;
	background: #dde1e7;
	border-radius: 25px;
	font-size: 22px;
  cursor: pointer;
  box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(18, 18, 19, 0.288);
}
.back1:hover{
  box-shadow: inset -3px -3px 7px #ffffff73, inset 3px 3px 5px rgba(18, 18, 19, 0.288);
}
.i1{
   position: absolute;
   top: 13px;
   left: 15px;
}
.i2{
  color: #6c757d;
}
.i3{
  color: rgba(245, 108, 109,1);
}
.back2{
  top: 55%;
  left: 4%;
  z-index: 1;
}
.back3{
  width: 210px;
	height: 25px;
  padding: 12px 0;
	position: absolute;
	top: 54%;
	left: 22%;
	background: rgba(245, 108, 109,1);
	border-radius: 25px;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
  box-shadow: -3px -3px 7px #ffffff73, 3px 3px 5px rgba(18, 18, 19, 0.288);
}
.back3:hover{
  box-shadow: inset -3px -3px 7px #ffffff73, inset 3px 3px 5px rgba(18, 18, 19, 0.288);
}
.back4{
  left: 56%;
}

@media all and (max-width: 450px) {
  :root {
    --fontsize-nav:1.2rem;
    --sidebar-flex:0;
    --typing-font:1.4rem;
  }
  body{
    height: 1400px;
  }
  .App
  {
    flex-direction: column;
  }
  .sidebar
  {
    padding: 30px;
  }
  .back1{
    right: 5%;
  }
  nav
  {
    width: 90%;
    top: 15%;
    left: 5%;
  }
  nav ul li
  {
    margin: 10px;
  }
  .neu2{
    top: 5%;
    left: 19%;
  }
  h2{
    margin-top: 80%;
    font-size: 2.2rem;
  }
  p{
    font-size: 1.4rem;
  }
  .condiv
  {
    top: 110%;
    left: 5%;
    width: 84.5%;
    height: 100%;
    margin-bottom: 50px;
  }
  h1{
    text-align: center;
    font-size: 2.8rem;
  }
  .p10{
    display: none;
  }
  .back2{
    top: 27%;
  }
  .vr_line{
    top: 30.5%;
    left: 10.5%;
  }
  h3{
    margin-top: 25px;
  }
  .sk_head{
    left: 0;
    top: 65%;
    margin-left: 30px;
  }.sk{
    top: 74%;
    left: 10%;
  }
  .s2{
    left: 50%;
  }
  .s3{
    top: 82%;
  }
  .s4{
    top: 82%;
    left: 50%;
  }
  .s5{
    top: 90%;
  }
  .s6{
    top: 90%;
    left: 50%;
  }
  .subtopic{
  margin: 10px !important;
  }
  .con_out{
    width: 88%;
    height: 20%;
  }
  .con_in{
    width: 94%;
    height: 86%;
  }
  .back3{
    top: 44%;
    left: 19%;
  }
  .back4{
    top: 54%;
    left: 19%;
  }
  .line2{
    margin: 1px 120px;
  }
  .inner2{
    margin: 15px;
  }
  .port_out{
    width: 80%;
    height: 26%;
  }
  .port1{
    top: 12%;
  }
  .port2{
    left: 10%;
    top: 42%;
  }
  .port3{
    display: none;
  }
  .port4{
    top: 72%;
    left: 10%;
  }
  .social
  {
    width: 90%;
    bottom: 5%;
    right: 5%;
  }
  .btnsame{
    margin: 15px 10px;
  }
}
